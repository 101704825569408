import apiClient from '@/services/axios'

export const getBlibliData = (business_id) => {
  return apiClient({
    method: 'get',
    url: 'channel/blibli/integration/query/client',
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getBlibliRequestData = ({ business_id, request_id, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/blibli/integration/query/channel/request/${request_id}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getBlibliPickupPoint = ({ business_id, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `channel/blibli/integration/query/warehouse/channel/${channel_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getMappingStatus = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: 'channel/blibli/integration/query/warehouse/map/request',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getBlibliDataDetail = ({ business_id, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `channel/blibli/integration/query/channel/${channel_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const authorizeBlibli = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: 'channel/blibli/integration/cmd/authorize/v2',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const mappingBlibliPickupPoint = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: 'channel/blibli/integration/cmd/warehouse/map/v2',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const disconnectBlibli = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: 'channel/blibli/integration/cmd/disconnect/v2',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const updateAutoAcceptOrder = ({ business_id, data }) => {
  return apiClient({
    method: 'put',
    url: 'channel/blibli/integration/cmd/data',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}