<template>
  <div v-if="!loading && error === false" id="app">
    <a-modal
      :visible="timeout"
      dialog-class="modal-idle"
      :closable="false"
      :footer="null"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h1 style="color: red">
          <a-icon type="exclamation-circle" />
        </h1>
        <h3>Waktu anda sudah habis</h3>
        <p class="mt-3 mb-5">
          Silahkan login kembali
        </p>
        <a-button key="submit" type="primary" @click="logout()">
          Logout
        </a-button>
      </div>
    </a-modal>
    <ModalNotificationWaitingExport />
    <transition name="slide-fade">
      <div v-if="visibleBackgroundProsesExportExcel" class="export-notification px-3 py-2 rounded-lg d-flex align-items-center" @click.prevent="onShowExportNotification">
        <a-spin class="mr-2" />Sedang export data, jangan refresh browser anda
      </div>
      <div v-if="syncProducts.length" class="export-notification px-3 py-2 rounded-lg d-flex align-items-center">
        <a-spin class="mr-2" /> Sedang sync produk&nbsp;
        <template v-for="(sync, index) in syncProducts">
          <b :key="sync.code">{{ $t(`dashboardContent.${sync.code}`) }}</b>
          {{ index == (syncProducts.length - 1) ? '' : ',&nbsp;' }}
        </template>
        , jangan refresh browser anda
      </div>
    </transition>
    <localization />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Localization from '@/localization'
import ConsoleLink from './components/ConsoleLink/ConsoleLink.vue'
import ModalNotificationWaitingExport from '@/views/sales/ModalNotificationWaitingExport.vue'

export default {
  name: 'App',
  components: { Localization, ConsoleLink, ModalNotificationWaitingExport },
  data() {
    return {
      loading: false,
      error: false,
      timeout: localStorage.getItem('idle') ? true : false,
      visibleBackgroundProsesExportExcel: false,
    }
  },
  computed: {
    keycloak() {
      return this.$keycloak
    },
    authenticated() {
      return this.$keycloak.authenticated
    },
    syncProducts() {
      return this.$store.state.productChannel.syncProducts
    },
    ...mapState(['settings']),
  },
  watch: {
    '$store.state.settings.theme'(theme) {
      this.$store.commit('settings/SET_THEME', { theme })
    },
    '$store.state.idleVue.isIdle'(idle) {
      if(idle) this.showModal()
    },
    $route(to) {
      const query = Object.assign({}, to.query)
      this.$store.commit('settings/SETUP_URL_SETTINGS', query)
    },
    '$store.state.exportData.exportFileNotification': {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.visibleBackgroundProsesExportExcel = value
        const loadingExport = this.$store.state.exportData.loadingExport
        if (!value && !!loadingExport) {
          this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
            showModalExportSales: true,
          })
        }
      },
    },
  },
  mounted() {
    this.$store.commit('settings/SET_PRIMARY_COLOR', {
      color: this.settings.primaryColor,
    })
    this.$store.commit('settings/SET_THEME', { theme: this.settings.theme })
    const accessToken = this.keycloak.token
    const { pathname } = window.location  
    if (pathname.includes('/confirmation/registered')) {
      this.setLoaded()
    } else {
      if (accessToken) {
        this.appClientInit()
      } else {
        this.setLoaded()
      }
    }
  },
  methods: {
    async appClientInit() {
      this.loading = true
      this.$store
        .dispatch('user/LOGINPROCESSING', {
          token: this.keycloak.token,
        })
        .then(() => {
          //
        })
        .catch((e) => {
          if (
            e.response.data.status === 404 &&
            this.$route.meta.title !== 'User Invitation' &&
            this.$route.meta.title !== 'User Confirm Invitation'
          ) {
            this.error = true
            const url = new URL(window.location.href)
            url.pathname = '/auth/user-not-found'

            this.$keycloak.logout({
              redirectUri: url.href,
            })
          } else {
            this.error = true
            const url = new URL(window.location.href)
            url.pathname = '/auth/login'

            this.$keycloak.logout({
              redirectUri: url.href,
            })
          }
        })
        .finally(() => {
          this.setLoaded()
        })
    },
    setLoaded() {
      this.loading = false
      const splashScreen = document.getElementById('pcaSplashScreen')
      splashScreen && splashScreen.classList.add('d-none')
    },
    showModal() {
      if (!this.$route.path.includes('auth')) {
        this.timeout = true
        localStorage.setItem('idle', true)
      }
    },
    logout() {
      localStorage.removeItem('idle')
      if (this.$keycloak.token) {
        this.$keycloak.logout()
      } else {
        window.location.href = window.location.origin
      }
    },
    onShowExportNotification() {
      this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
        exportFileNotification: false,
      })
    },
  },
  metaInfo: {
    link: [
      {
        rel: 'icon',
        href: 'https://ik.imagekit.io/powerbiz/img/admin-saas/powerbiz.png?updatedAt=1620629008506',
      },
    ],
  },
}
</script>
<style lang="scss">
.export-notification {
  position: fixed;
  left: 50%;
  top: 20px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 1px 10px 15px rgba(0, 0, 0, 0.1) !important;
  transition: opacity 0.6s linear;
  cursor: pointer;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>